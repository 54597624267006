import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import style from './style.module.css';
import orderedIcon from 'assets/orderedIcon.png';
import orderedIconRed from 'assets/orderedIconRed.png';
//import { CircularProgressbarWithChildren, buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import { set } from 'mobx';
//import { isMobile } from 'react-device-detect';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class HistItem extends React.Component {
    
    statuses = ['received', 'rejected', 'pickup_eta_updated', 'picked_up', 'delivered', 'customer_no_show'];
    statusesToShowPosition = this.statuses;//['picked_up', 'delivered', 'customer_no_show', null];

    // getFormatedDate = (timestamp) => {
    //     const date = new Date(timestamp * 1000)
    //     return date.toLocaleDateString('he-IL');
    // }

    getFormatedStatus = (status) => {
        const { t } = this.props; 
        const knownStatuses = ['new', 'in_process', 'preparing', /*'delivery',*/ 'in_delivery', 'paid', 'collected'];
        if (status === 'delivery') status = 'in_delivery';
        return knownStatuses.indexOf(status) > -1 ? t(status) : '---';
    }
    
    handleClick = () => {
        const { setBasketHistory } = this.props.myShopApiStore;
        const { order, showBasket, basketVisible, isFirstOrder, showCourier  } = this.props;
        setBasketHistory(order);
        showCourier(false);
        
        !basketVisible && showBasket();
    }

    cancelDelivery = () => {
        const { order } = this.props;
        const { shop, my_env } = this.props.myShopApiStore;

        axios.get(`${my_env.url}/api/cancel-delivery?orderId=${order.id}_${shop.id}`).then(res => {
            console.log(res);
        })
    }
   
    componentDidMount = () => {
        this.props.isFirstOrder && window.innerWidth > 850 && this.handleClick();
    }

    render() {
        const { order, t, getShippingStatus, basketVisible } = this.props;
        const { basketHistory, setBasketHistory, shop, shopData: {country: {currencyName, alfa2Code, defaultLang}} } = this.props.myShopApiStore;
        // const { currencyName, alfa2Code, defaultLang } = shopData.country;
        // const shippingStatus = getShippingStatus(order.id);
        //const shippingStatus = null;
        const isActive = basketHistory?.id === order?.id;
        const locale = alfa2Code ? `${defaultLang}-${alfa2Code}` : 'he-IL';

        return (
            <div className={style.container} onClick={this.handleClick}
                 style={{backgroundColor: isActive ? "#E0E0E0" : "#FFF"}}>
                <div className={style.TopDiv}>
                    <div className={style.info}>
                        <div className={style.infoRow}>
                            <div className={style.col}>
                                <div className={style.colDesc}>{t('date')}</div>
                                <div className={style.colVal}>
                                    {(new Date(order.createdDate * 1000)).toLocaleDateString(locale)}
                                </div>
                            </div>
                            <div className={style.col}>
                                <div className={style.colDesc}>{t('order amount')}</div>
                                {!shop.withoutPrices && <div className={style.colVal}>{`${currencyName}${order.totalPay.toFixed(2)}`}</div>}
                            </div>
                            <div className={style.col}>
                                <div className={style.colDesc}>{t('order number')}</div>
                                <div className={style.colVal}>{order.id}</div>
                            </div>
                        </div>
                        <hr className={style.line}/>
                        <div className={style.infoRow}>
                            <div className={style.col}>
                                <div className={style.colDesc}>{t('order type')}</div>
                                <div className={style.colVal}>
                                    {
                                        ['delivery', 'pickup'].indexOf(order.orderType) > -1
                                            ?   t(order.orderType)
                                            :   t('inhouse')
                                    }
                                </div>
                                {typeof order.delivery !== 'undefined' && this.statusesToShowPosition.includes(order.delivery?.deliveryStatus?.codename) &&
                                    <button className={style.button} 
                                            onClick={(e)=> {
                                                e.stopPropagation();
                                                setBasketHistory(order);
                                                this.props.showCourier();
                                        }}>
                                            {t('courier location')}
                                    </button>}
                                {this.props.myShopApiStore.userParams.email.includes('my-shop.co.il') && 
                                    <button className={style.button} style={{backgroundColor: '#ee0028', textDecorationLine: 'line-through'}}
                                            onClick={(e)=> {
                                                isActive && e.stopPropagation(); 
                                                this.cancelDelivery();
                                        }}>
                                            {t('Cancelation')}
                                    </button>
                                }
                            </div>
                            <div className={style.col}>
                                <div className={style.colDesc}>{t('arrival time')}</div>
                                <div className={style.colVal}>
                                    {
                                        `${order.deliveryFrom} - ${order.deliveryTo}`
                                    }
                                </div>
                                <div className={style.colVal}>
                                    {(new Date(order.deliveryDate*1000)).toLocaleDateString(locale)}
                                </div>
                            </div>
                            <div className={style.col}>
                                <div className={style.colDesc}>{t('order status')}</div>
                                <div className={style.colVal}>
                                    {this.getFormatedStatus(order.status)}
                                    {/* {this.state.counter} */}
                                    {/* {order.status} */}
                                </div>
                                { typeof order.delivery !== 'undefined' && <>
                                    <div className={style.colDesc}>{t('delivery status')}</div>
                                    <div className={style.colVal}>{order.delivery?.deliveryStatus ? t(order.delivery?.deliveryStatus.codename) : '----'}</div> 
                                </>}
                            </div>
                            {/* <div className={style.col_del}>
                            {
                                shippingStatus
                                ?
                                    <>
                                        <div>
                                            <div className={style.colDesc}>זמן הגעה משוער</div>
                                            <div className={style.colDesc}>של השליח</div>
                                        </div>

                                        <div className={style.timerWrapper}>
                                            <CircularProgressbarWithChildren styles={buildStyles({pathColor: '#ee0028'})} value={Math.ceil(shippingStatus.initialTime/60) - Math.ceil(shippingStatus.time/60)} strokeWidth={5} minValue={0} maxValue={Math.ceil(shippingStatus.initialTime/60)} size={1}>
                                                <div className={style.inner}>
                                                    <div className={style.time}>{Math.ceil(shippingStatus.time/60)}</div>
                                                    <div className={style.unit}>דק'</div>
                                                </div>
                                            </CircularProgressbarWithChildren>
                                        </div>
                                    </>
                                :
                                    null
                            }
                            </div> */}
                        </div>
                    </div>
                  
                    <div className={style.image}>
                        <img src={order.status === 'paid' ? orderedIconRed : orderedIcon } alt='' width='40px' />
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(HistItem);