import React from "react";
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next";
import MaxToppingsMsg from "../MaxToppingsMsg";
import style from "./style.module.css";

const SubForm2 = ({
  currencyName,
  handleChange,
  shopToppings,
  selectedToppings,
  toppingsDescription,
  toppingsFree,
  hasError,
  maxToppings,
  toppingsAddPaid,
  closeMaxToppingMsg,
  oneToppingMode,
  withoutPrices
}) => {
  
  const { t } = useTranslation();
  
  const selectedToppingAmount = (id) => { 
    return selectedToppings
              .filter(topping => topping.id == id)
              .reduce((acc, top)=>acc + (top.id == id) ? top.shopToppingAmount : 0, 0);
  }

  const selectedToppingSum = (id) => {
    let sum = selectedToppings.filter((item)=>item.id == id && item.sum>0)[0]?.sum;
    return sum 
            ? <NumberFormat value={sum} prefix={currencyName} decimalScale={2} fixedDecimalScale={true} displayType={"text"}/>
            : t('free'); 
  }

  const toppingPrice = (id) => {
    let price = shopToppings.find((item)=>item.id == id).price;
    return price
            ? <NumberFormat value={price} prefix={currencyName} decimalScale={2} fixedDecimalScale={true} displayType={"text"}/>
            : t('free');
  }

  return (
    <div className={style.Container}>
      <div className={style.ToppingsDescription}>{toppingsDescription??''}</div>
      
      { hasError ? <MaxToppingsMsg closeMaxToppingMsg={closeMaxToppingMsg}
                          limitedToppingsNumber={(toppingsAddPaid===0 && maxToppings > toppingsFree)
                                              || maxToppings === null || maxToppings === 0 ? toppingsFree : maxToppings}/> : null }
      { oneToppingMode === 0 ?  <div className={style.RightSideTabs}> 
        <div className={style.SelectedToppings}>
          {
            selectedToppings.map(topping =>{
              return (
                <div className={style.ToppingToEdit}
                  title={topping.id}
                  key={topping.id}
                  >
                    <div className={style.ToppingNameAndPrice}>
                      <div className={style.ToppingName}>
                        <span className={style.ToppingXRemover} 
                          onClick={(e)=>handleChange(e, {className:'buttonMinus', name:'selectedToppings', type:'checkbox', value: topping.id})}>✕</span>
                        {topping.name}
                      </div>
                      <div className={style.ToppingPrice}>
                        {!withoutPrices && (topping.sum?(<NumberFormat value={topping.sum} prefix={currencyName} decimalScale={2} fixedDecimalScale={true} displayType={"text"}/>):(t('free')))}
                      </div>
                    </div>
                </div>)})
          }
        </div>
      </div>: ''}
      <div className={style.ItemToppingList}>
      {shopToppings.map((st, idx) => {
          return (
            <div key={idx} className={style.ItemTopping} >
                <input
                  type="checkbox"
                  value={st.id}
                  name="selectedToppings"
                  checked={selectedToppings.some(topping => topping.id === st.id)}
                  onChange={(e) => handleChange(e)}
                />
              <div className={style.WrapBtnAndName} style={{textAlign: document.body.dir === 'rtl' ? 'right' : 'left'}}>
                <span className={style.ItemToppingName} style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{st.topping.name}</span>
              </div>

              { oneToppingMode === 0
                ? <> 
                  <span className={style.ItemToppingPrice} >
                    {!withoutPrices && (( toppingsFree>selectedToppings.reduce((acc, item) => acc + item.shopToppingAmount,0))?t('free'):toppingPrice(st.id))}
                  </span>
                  <div className={style.btnContainer}>
                    <div className={style.InputWithCounter}>
                      <div type="button" name="selectedToppings" className={style.buttonPlus} value={st.id} onClick={(e)=>{handleChange(e, {className:'buttonPlus', name:'selectedToppings', type:'', value: st.id})}}>+</div>
                      <div name="countTopping" className={style.ItemAmount}>{selectedToppingAmount(st.id)}</div>
                      <div name="selectedToppings" className={style.buttonMinus} value={st.id} onClick={(e)=>handleChange(e, {className:'buttonMinus', name:'selectedToppings', type:'', value: st.id})}>-</div>
                    </div>
                  </div>
                </> 
                :<span className={style.ItemToppingPrice}>
                  {!withoutPrices && 
                    (( toppingsFree>selectedToppings.reduce((acc, item) => acc + item.shopToppingAmount,0))
                    ? t('free')
                    : (selectedToppings.some(topping => topping.id === st.id) 
                        ? selectedToppingSum(st.id) 
                        : toppingPrice(st.id)
                      ))
                  }
                </span>
              } 
              
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubForm2;
